<template>
    <div id="basic-table" class="mainbox">
        <sn-bread :itemName="itemName" :breadlist="breadlist"></sn-bread>
        <sn-search
            class="sn-search clearfix"
            :snSearch.sync="snSearch"
            @handleChange="handleChange"
        />
        <div
            v-if="picList.length > 0"
            class="carouselPart carouselArrowShow"
            v-viewer
        >
            <div v-show="showFirst">
                <el-carousel
                    height="450px"
                    indicator-position="none"
                    :autoplay="false"
                >
                    <el-carousel-item
                        v-for="{ ID, PMHLMMC, RKSJ, LJ } in picList"
                        :key="ID"
                    >
                        <div class="topDesc flex-both-center">
                            遗产要素单体局部测绘图
                        </div>
                        <el-image
                            :src="LJ"
                            fit="cover"
                            class=" pointer"
                            style="width: 100%; height: 100%"
                        >
                            <div
                                slot="placeholder"
                                style="width: 100%; height: 100%"
                            >
                                加载中
                                <span class="dot">...</span>
                            </div>
                            <div slot="error" style="width: 100%; height: 100%">
                                <SnNoData typeNum="1"></SnNoData>
                            </div>
                        </el-image>
                        <div class="bottomDesc">
                            <div class="label">立面名称：{{ PMHLMMC }}</div>
                            <div class="label">创建时间：{{ RKSJ }}</div>
                        </div>
                    </el-carousel-item>
                </el-carousel>
            </div>
            <div v-show="!showFirst">
                <el-carousel height="450px" indicator-position="none">
                    <el-carousel-item
                        v-for="{ ID, PMHLMMC, RKSJ, LJ } in picList"
                        :key="ID"
                    >
                        <div class="topDesc flex-both-center">
                            遗产要素单体局部照片
                        </div>
                        <el-image
                            :src="LJ"
                            fit="cover"
                            class=" pointer"
                            style="width: 100%; height: 100%"
                        >
                            <div
                                slot="placeholder"
                                style="width: 100%; height: 100%"
                            >
                                加载中
                                <span class="dot">...</span>
                            </div>
                            <div slot="error" style="width: 100%; height: 100%">
                                <SnNoData typeNum="1"></SnNoData>
                            </div>
                        </el-image>
                        <div class="bottomDesc">
                            <div class="label">照片名称：{{ PMHLMMC }}</div>
                            <div class="label">创建时间：{{ RKSJ }}</div>
                        </div>
                    </el-carousel-item>
                </el-carousel>
            </div>
        </div>
        <div v-else class="empty">
            未能查询到数据
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
    props: {
        itemName: {
            type: String,
            default: function() {
                return "";
            },
        },
        currentChild: {
            default: function() {
                return {};
            },
        },
    },
    data() {
        return {
            cjdObj: {},
            snSearch: {
                radioData: [
                    {
                        label: "",
                        group: [
                            { label: "局部测绘图", value: 13 },
                            { label: "局部照片", value: 14 },
                        ],
                        value: "LBBM",
                        operateType: "search",
                        isShow: true,
                    },
                ],
                searchBtn: {
                    buttonData: [
                        {
                            btnType: "button",
                            operateType: "searchBtn",
                            name: "查询",
                            round: true,
                            backColor: "#2a63d5",
                            color: "#fff",
                        },
                    ],
                },
                selectData: [
                    {
                        label: "遗产要素列表",
                        clearable: true,
                        placeholder: "请选择遗产要素列表",
                        list: [],
                        optionLabel: "NAME",
                        optionValue: "ID",
                        value: "YCYSBM",
                        operateType: "search",
                        isShow: true,
                    },
                ],
            },
            tableId: "",
            breadlist: [
                {
                    path: "",
                    name: "工作台面",
                },
                {
                    path: "",
                    name: "监测数据",
                },
            ],
            keyword: "",
            searchData: [
                { type: "YCYSBM" },
                { type: "PMHLMMC" },
                { type: "LBBM", value: 13 },
            ],
            showFirst: true,
            picList: [],
        };
    },
    computed: {},
    watch: {
        searchData: {
            immediate: true,
            deep: true,
            handler: function([, , { value: radio }]) {
                this.showFirst = Number(radio) === 14 ? false : true;
            },
        },
    },
    created() {
        this.getYcysData();
    },
    mounted() {
        this.$nextTick(() => {
            this.getTableData();
        });
    },
    methods: {
        ...mapActions([
            "getMonitorDataList",
            "deleteBasicData",
            "getMonitorDataDetails",
            "deleteMonitorData",
            "GetYcysList",
            "getCjd",
        ]),

        handleChange(type, value) {
            console.log("type", type, "value", value);
            switch (type) {
                case "search": // 修改搜索条件(搜索及按钮)
                    if (["YCYSBM", "PMHLMMC", "LBBM"].includes(value[1])) {
                        this.searchData.forEach((item) => {
                            if (item.type === value[1]) {
                                item.value = value[0];
                            }
                        });
                    } else {
                        this[value[1]] = value[0];
                    }
                    if (["LBBM"].includes(value[1])) {
                        this.searchData = [...this.searchData];
                    }
                    this.getTableData(); // 是否需要点击查询按钮才进行搜索
                    break;
                case "searchBtn": // 点击查询按钮
                    this.getTableData();
                    break;
                default:
                    break;
            }
        },
        // 获取表格数据
        async getTableData() {
            // 处理筛选，支持多项同时筛选
            let strwhere = "";
            if (this.searchData && this.searchData.length > 0) {
                this.searchData.forEach((item) => {
                    if (item.value) {
                        strwhere += '"' + item.type + "," + item.value + '",';
                    }
                });
            }
            strwhere = strwhere.slice(0, strwhere.length - 1);
            strwhere = "[" + strwhere + "]";
            let result;
            // 请求图片列表
            result = await this.getMonitorDataList({
                userid: localStorage.userId,
                itemId: 304,
                authority: false,
                keyWord: this.keyword,
                strwhere: strwhere,
                sort: null,
                sortorder: null,
                start: null,
                end: null,
                pageIndex: null,
                pageSize: null,
            });
            if (result.ResultValue) {
                const validData = this.getValidData(
                    result.ResultValue.gridData
                );
                this.picList = validData;
            }
        },
        getValidData(gridData) {
            const baseUrl = window.DOWN_URL;
            const result = [];
            if (gridData.length > 0) {
                gridData.forEach((ele) => {
                    const temp = JSON.parse(JSON.stringify(ele));
                    if (ele.SYTLJ !== "") {
                        temp.LJ = temp.SYTLJ.startsWith("http")
                            ? temp.SYTLJ
                            : `${baseUrl}${temp.SYTLJ}`;
                        result.push(temp);
                    } else if (ele.PIC && ele.PIC.length > 0) {
                        ele.PIC.forEach((item) => {
                            const innerTemp = JSON.parse(JSON.stringify(temp));
                            innerTemp.LJ = item.CCLJ;
                            result.push(innerTemp);
                        });
                    }
                });
            }
            return result;
        },
        async getYcysData() {
            const data = await this.GetYcysList();
            const res = data && data.ResultValue;
            if (res) {
                this.snSearch.selectData[0].list = [];
                res.map((item) => {
                    let obj = {
                        ID: item.BM,
                        NAME: item.MC,
                    };
                    this.snSearch.selectData[0].list.push(obj);
                });
            }
        },
        resetSearchData() {
            this.searchData[0].value = this.snSearch.selectData[0].list[0].ID;
        },
        changeModule() {},
    },
};
</script>

<style lang="scss" scoped>
.mainbox {
    width: 100%;
    height: 100%;
    position: relative;
    .empty {
        padding: 20px 0 0 30px;
        font-size: 14px;
    }
    .carouselPart {
        width: 700px;
        height: 450px;
        margin: 0 auto;
        margin-top: 20px;
        position: relative;

        .topDesc {
            height: 40px;
            background: gray;
            position: absolute;
            top: 0;
            width: 100%;
            opacity: 0.8;
            z-index: 1;
            color: #fff;
        }
        .bottomDesc {
            height: 50px;
            background: gray;
            position: absolute;
            bottom: 0;
            width: 100%;
            opacity: 0.8;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding-left: 20px;
            justify-content: center;
            color: #fff;
        }
        img {
            max-height: 450px;
            min-height: 450px;
            max-width: 700px;
            min-width: 700px;
            object-fit: cover;
        }
    }
}
</style>
